@import "react-quill/dist/quill.snow.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* text-transform: capitalize; */
}

/* a {
  text-decoration: none !important;
  color: #fff !important;
} */

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.my-editing-area {
  font-size: "50px";
}

.rs-container {
  height: 100vh;
}

.rs-sidebar {
  height: 100vh;
  position: absolute;
  background: rgb(255, 255, 255);
  z-index: 9999;
  overflow: hidden;
  /* box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5); */
}

.rs-sidenav-header {
  height: 60px;
  background: #303c54;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.rs-sidenav {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5);
  background: #44536b !important;
}

.rs-sidenav::-webkit-scrollbar {
  display: none;
}

.rs-sidenav-item:hover {
  background: #3e4a5e !important;
  color: #fff !important;
}

.rs-sidenav-item:hover a {
  color: #fff !important;
}

.rs-dropdown-item:hover {
  background: #404c61 !important;
  color: #fff !important;
}

.rs-dropdown-item:hover a {
  background: #404c61 !important;
  color: #fff !important;
}

.rs-sidenav-item-active {
  background: #3e4a5e !important;
  color: #fff !important;
}

.nav-toggle {
  box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5);
}

.rs-header {
  height: 55px;
  margin-left: 55px;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 10%);
  z-index: 1;
}

.rs-content {
  background: #f8f8f8;
  width: calc(100vw - 58px);
  margin-left: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.rs-dropdown-toggle {
  background: #44536b;
  color: #fff;
}

.rs-sidenav-nav a {
  color: #8e8e93 !important;
  text-decoration: none !important;
}

/* TABLE STYLING */
.rs-table-body-wheel-area {
  background-color: #fff !important;
}

/* LOGIN PAGE STYLE */

.loginContainer {
  background: url("./assets/pexels-taryn-elliott-4840134.jpg");
  background-size: cover;
}

.loginDiv {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  min-width: 350px;
}

/* MODAL STYLING */
.rs-modal-body {
  overflow-x: hidden !important;
  padding-bottom: 0 !important;
}

.rs-table-cell-header .rs-table-cell-content {
  padding: 0 !important;
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-weight: bold;
}

.f-name-bold{
  font-weight: bold;
}

.rs-table-cell-content {
  padding-left: 8px 5px !important;
  display: flex !important;
  justify-content: start !important;
  align-items: center !important;
}

.myCheckCell .rs-table-cell-content {
  padding-left: 10px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.paginationDiv {
  padding: 5px;
  background: #fff;
}

.bioDataModal .rs-modal-content {
  width: 21cm !important;
  min-height: 29.7cm !important;
  padding: 0.5cm !important;
  margin: 1cm auto !important;
  background: white !important;
}

.bioDataModal .rs-modal-body {
  max-height: 29.7cm !important;
}

.marriage-table td {
  width: 50%;
}

.react-tabs__tab-list {
  margin: 0 !important;
}

.react-tabs__tab-list {
  display: flex !important;
  justify-content: center !important;
  border-bottom: none !important;
}

/* .react-tabs__tab-panel--selected {
  border: 1px solid #aaa;
} */

.react-tabs__tab--selected {
  background: #303c54;
  border-color: #fff;
  color: #fff;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
}

.rs-table-cell-content {
  color: #000 !important;
  font-size: 14px;
}

.marriageImageSingle {
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 2rem;
  width: 175px;
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagePreviewSingle {
  position: relative;
}

.deleteSingle {
  position: absolute;
  right: 3px;
  top: 4px;
  z-index: 2;
  background: red;
  border-radius: 50%;
  padding: 2px 7px 5px;
  color: #fff;
}

.sideToggle {
  display: none;
}

.imagePreviewSingle {}

.imagePreviewSingle img {
  width: 100%;
}

.copyrightSec {
  text-align: center;
}

.copyrightSec p {
  margin: auto;
  padding: 10px 0;
}

.loginCopyrightSec {
  position: fixed;
  z-index: 99999;
  color: #fff;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px 0;
}

.loginCopyrightSec a,
.loginCopyrightSec a:hover,
.loginCopyrightSec a:focus,
.loginCopyrightSec a:visited {
  color: #ff6f7d;
}

.headline .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.4;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.marriageImageSingleNews {
  padding: 2rem;
  border: 1px solid #ddd;
  width: 100%;
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .rs-content {
    width: 100vh;
  }

  .rs-header {
    margin-left: 0;
  }

  .sideToggle {
    display: block;
  }
}

.new-tab ul.mb-3.nav.nav-tabs {
  background-color: rgb(142, 12, 12);
}

.nav-tabs .nav-link {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.head_bal {
  text-align: end;
  margin-right: 200px;
}

.paidColor {
  background-color: cyan;
}

input[name="email"] {
  text-transform: lowercase;
}

input[name="username"] {
  text-transform: lowercase;
}



.table-row {

border: 1px solid rgb(180, 175, 175);

}




/* input[type="text"] {
  text-transform: lowercase;
} */